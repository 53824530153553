import React from "react";
import Layout from "../components/layout";
import WhiteCard from "../components/white-card";
const h1ClassNames = "text-center font-bold text-3xl pb-5";
const h2ClassNames = "italic font-bold text-2xl pt-4";
const h3ClassNames = "pt-2 font-bold text-xl";
const pClassNames = "pt-4 pl-2 text-lg";
const ulClassNames = "pl-2";
const olClassNames = "list-decimal pl-4";
const liClassNames = "pt-2 text-lg";
const HvacPage = () => {
  return (
    <Layout>
      <WhiteCard>
        <section>
          <h1 className={h1ClassNames}>Heat and Air Conditioning</h1>
          <p className={pClassNames}>
            The Overlook Chalet uses a state-of-the-art Mitsubishi heat pump
            system for both <b>heating</b> and <b>cooling</b>. These systems are
            particularly popular in upstate New York because they create the
            optimal comfort for each room for all seasons. Our system was
            installed in October of 2022.
          </p>
          <h2 className={h2ClassNames}>Which rooms have minisplit units?</h2>
          <p className={pClassNames}>
            Each room has a minisplit unit that has been optimized for the
            respective size (cubic footage). This includes <b>all 5 bedrooms</b>
            , the living room / kitchen and the basement.
          </p>
          <p className={pClassNames}>
            Unit capacities were selected based on an in-depth analysis of the
            space and seasonal climate in the Catskills region.
          </p>
          <h2 className={h2ClassNames}>
            How do I set my preferred temperature?
          </h2>
          <p className={pClassNames}>
            Temperature is controlled in each room using a remote. You can find
            these mounted on the walls. Once the temperature is set, the unit
            will maintain the desired temperature using both traditional
            thermostats and infrared temperature detectection, ensuring
            consistent temperature in the entire room.
          </p>
          <h2 className={h2ClassNames}>Better for the planet</h2>
          <p className={pClassNames}>
            Heat pump ductless systems, like the one used at the Overlook
            Chalet, use inverter technology, allowing them to operate using
            considerably less energy the traditional central systems and window
            unit systems. These systems use micro-controllers to sample air
            temperature in each zone (or each room) at a high rate, and adjust
            compressors to the ideal speed for each room. This means that you'll
            be more comfortable in each room since the temperature will be
            maintained precisely. It's better for you, and the planet.
          </p>
          <h2 className={h2ClassNames}>What if I like a breeze?</h2>
          <p className={pClassNames}>
            If you prefer a breeze, you have a few options at the Overlook
            Chalet. All rooms have ceiling fans. These, combined with our
            ductless system make for an extremely comfortable environment.
          </p>
          <p className={pClassNames}>
            Each minisplit unit also has a <em>Natural Flow</em> setting, which
            mimics the natural breeze of the outdoors. Yet another way to make
            your stay optimally comfortable.
          </p>
        </section>
      </WhiteCard>
    </Layout>
  );
};
export default HvacPage;
