import React from "react";

const WhiteCard = ({ children }) => {
  return (
    <div className="w-full md:w-9/12 lg:w-9/12 bg-kz-beige-light p-3 md:p-6 lg:p-10 flex-row rounded drop-shadow-lg m-auto">
      {children}
    </div>
  );
};

export default WhiteCard;
