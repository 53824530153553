import React from "react";
import GuestHeader from "./guest-header";
import { useLocation } from "@reach/router";
import Helmet from "react-helmet";
import * as cx from "classnames";
const Layout = (props) => {
  const location = useLocation();
  const { children } = props;
  const containerClasses = cx("flex-row h-full align-center bg-center", {
    "bg-index": location.pathname === "/",
    "bg-oc-green-dark": location.pathname !== "/",
  });
  return (
    <main className={`mx-auto flex-row p-0 w-full font-serif bg-oc-green-dark`}>
      <div id="page-wrap" className={containerClasses}>
        <GuestHeader />
        <div id="main-wrap" className="p-8">
          {children}
        </div>
      </div>
    </main>
  );
};

export default Layout;
