import React from "react";
import { slide as Menu } from "react-burger-menu";
import logo from "../images/overlooklogo.png";
import * as cx from "classnames";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const burgerStyles = {
  bmBurgerButton: {
    position: "fixed",
    width: "36px",
    height: "30px",
    right: "36px",
    top: "36px",
  },
  bmBurgerBars: {
    background: "#373a47",
  },
  bmBurgerBarsHover: {
    background: "#a90000",
  },
  bmCrossButton: {
    height: "24px",
    width: "24px",
  },
  bmCross: {
    background: "#bdc3c7",
  },
  bmMenuWrap: {
    position: "fixed",
    height: "100%",
  },
  bmMenu: {
    background: "#373a47",
    padding: "2.5em 1.5em 0",
    fontSize: "1.15em",
  },
  bmMorphShape: {
    fill: "#373a47",
  },
  bmItemList: {
    color: "#b8b7ad",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
  },
  bmItem: {
    display: "block",
    padding: "1rem",
    fontSize: "1.5rem",
  },
  bmOverlay: {
    background: "rgba(0, 0, 0, 0.4)",
  },
};
const menuItemClassNames = "mt-3 text-2xl";

const GuestHeader = () => {
  const parentClasses = cx(
    "h-24",
    "flex",
    "text-oc-gray",
    "text-lg",
    "bg-amber-50/80",
    "w-full",
    "justify-between",
    "border-b-2",
    "border-slate-700"
  );
  return (
    <div className={parentClasses}>
      <div className="grow m-auto h-full items-center">
        <a id="home" href="/">
          <img alt="logo" className="h-full p-4 m-auto" src={logo} />
        </a>
      </div>
      <Menu
        styles={burgerStyles}
        pageWrapId={"main-wrap"}
        outerContainerId={"page-wrap"}
        right
      >
        <AnchorLink
          id="menu-houseaccess"
          className={menuItemClassNames}
          to="/guests#houseaccess"
        >
          House Access
        </AnchorLink>
        <AnchorLink
          id="menu-general"
          className={menuItemClassNames}
          to="/guests#general"
        >
          General
        </AnchorLink>
        <AnchorLink
          id="menu-internet"
          className={menuItemClassNames}
          to="/guests#internet"
        >
          Internet/Streaming
        </AnchorLink>

        <AnchorLink
          id="menu-livingroom"
          className={menuItemClassNames}
          to="/guests#livingroom"
        >
          Living Room
        </AnchorLink>
        <AnchorLink
          id="menu-readingnook"
          className={menuItemClassNames}
          to="/guests#readingnook"
        >
          Reading Nook
        </AnchorLink>
        <AnchorLink
          id="menu-fireplace"
          className={menuItemClassNames}
          to="/guests#fireplace"
        >
          Fireplace
        </AnchorLink>
        <AnchorLink
          id="menu-outdoors"
          className={menuItemClassNames}
          to="/guests#outdoors"
        >
          Outdoors
        </AnchorLink>
        <AnchorLink
          id="menu-firepit"
          className={menuItemClassNames}
          to="/guests#firepit"
        >
          Fire Pit
        </AnchorLink>
        <AnchorLink
          id="menu-hottub"
          className={menuItemClassNames}
          to="/guests#hottub"
        >
          Hot Tub
        </AnchorLink>
        <AnchorLink
          id="menu-basement"
          className={menuItemClassNames}
          to="/guests#basement"
        >
          Basement
        </AnchorLink>
        <AnchorLink
          id="menu-bathrooms"
          className={menuItemClassNames}
          to="/guests#bathrooms"
        >
          Bathrooms
        </AnchorLink>
        <AnchorLink
          id="menu-kitchen"
          className={menuItemClassNames}
          to="/guests#kitchen"
        >
          Kitchen
        </AnchorLink>
        <AnchorLink
          id="menu-laundry"
          className={menuItemClassNames}
          to="/guests#laundry"
        >
          Laundry
        </AnchorLink>
        <AnchorLink
          id="menu-trash"
          className={menuItemClassNames}
          to="/guests#trash"
        >
          Trash 
        </AnchorLink>
        <AnchorLink
          id="menu-safety"
          className={menuItemClassNames}
          to="/guests#safety"
        >
          Safety info 
        </AnchorLink>
        <AnchorLink
          id="menu-checkout"
          className={menuItemClassNames}
          to="/guests#checkout"
        >
          Check out instructions
        </AnchorLink>
      </Menu>
    </div>
  );
};

export default GuestHeader;
